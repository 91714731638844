import React from 'react'

import { Header } from 'components/Header'
import HtmlParser from 'components/HtmlParser'
import CompanyInfo from 'components/pages/info/components/CompanyInfo'
import Seo from 'components/seo'

// import * as styles from './info.module.scss'

const Homepage = ({ pageContext: { pageData } }) => {
  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />

      <Header
        h1={pageData.header.h1}
        breadcrumbs={[{
          title: pageData.title,
        }]}
        isWide
      />

      <section>
        <div className="container">
          <div className="columns main__body agb__body">
            <div className="column main__content">
              <HtmlParser htmlString={pageData.content} />
            </div>

            <CompanyInfo />
          </div>
        </div>
      </section>
    </>
  )
}

export default Homepage
