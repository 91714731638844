import React, {useContext} from 'react'

import SiteContext from 'helpers/siteContext'

const CompanyInfo = () => {
  const siteData = useContext(SiteContext)
  const data = siteData.companyInfo

  if (!data) {
    return <></>
  }

  return (
    <aside className="column main__aside">
      <ul className="privacy__aside_list">
        {
          data.map((item, i) =>
            <li className="main__aside_listItem" key={i}>
              <p className="main__aside_title">
                {item.name}:
              </p>
              <p className="main__aside_desc">
                {item.value}
              </p>
            </li>
          )
        }
      </ul>
    </aside>
  )
}

export default CompanyInfo
